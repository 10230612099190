.Form{
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

@media screen and (max-width:980px) {

    .Form{
        display: flex;
        flex-direction: column;
        width: 90%;
        height: auto;
    }
    
}