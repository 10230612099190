.NavContainer{
        width: 100%;
        display: flex;
        padding: 1rem;
        background: green;
        z-index: 9999;
}

.leftNav{
    width: 10%;
}

.navIcon{
    font-size: 2em;
    font-weight: 400;
    color: white;
    transition: background 0.5s, ;

}

.navIcon:hover{
    color: green;
    background: white;
    border-radius: 20%;
}

.RightNav{
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;
    color: white;
}

.sideNav {
    z-index: 9999;
    position: absolute;
    background: white;
    transition: transform 0.5s ease;
    width: 22%;
    min-height: 150%;
    margin-top: 3.3%;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transform: translateX(-100%);
    left: 0;
}

.sideNav.open {
    transform: translateX(0); /* Slide in */
}

.sideNav ul{
    list-style: none;
    padding: 2rem;
}

.sideNav ul li{
   margin-bottom: 1rem;
   padding: 0.2rem;
   border-bottom: 1px solid rgb(204, 200, 200);
  transition: background 0.5s, padding 0.5s;

}



.sideNav ul li:hover{
    background: green;
    color: white;
    cursor: pointer;
    padding-left: 0.7rem;
    border-radius: 5px;
}
@media screen and (max-width:980px) {

    .NavContainer{
        width: 100%;
    }
    .navIcon{
        font-size: 2em;
        font-weight: 400;
        color: white;
    }

    .RightNav{
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5em;
        font-weight: bold;
        color: white;
    }

    .sideNav{
        width: 70%;
        margin-top: 12.5%;
    }
}