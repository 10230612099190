.orderTableContainer {
    height: 100vh;
    overflow: auto;
    padding: 1rem;
}

.orderTableContainer table {
    width: 100%; /* Full width by default */
    text-align: center;
    border-collapse: collapse;
    table-layout: fixed; /* Ensure the table keeps column widths consistent */
}

th, td {
    padding: 0.8rem;
    border-bottom: 1px solid rgb(7, 7, 8);
    white-space: nowrap; /* Prevent text from wrapping */
}

thead {
    background: #FFE505;
    color: #000;
    position: sticky; /* Stick the table header to the top while scrolling */
    top: 0;
    z-index: 1;
}

tbody tr:hover {
    background: #000;
    color: #FFE505;
    cursor: pointer;
}

@media screen and (max-width: 980px) {

    .orderTableContainer {
        overflow-x: auto; /* Enable horizontal scroll for smaller screens */
        height: auto;
    }

    .orderTableContainer table {
        width: auto; /* Let the table expand horizontally */
        min-width: 600px; /* Ensure the table doesn't get too narrow */
    }

    th, td {
        padding: 1rem;
        font-size: 14px;
        white-space: nowrap; /* Ensure table cells don't wrap */
    }

    thead {
        display: table-header-group; /* Ensure the table head stays visible */
    }

    tbody tr {
        display: table-row; /* Keep rows as table rows, no block display */
        width: 100%;
    }

    tbody td {
        text-align: left;
    }
}
