.ProductCat{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;    
}

.ProductCatForm{
    width: 90%;
    height: 100%;
}

@media screen and (max-width:980px) {

    .mobileForm{
        display: flex;
        flex-direction: column;
    }
}