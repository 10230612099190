.DashBoradContainer{
    width: 80%;
    height: 90vh;
    display: grid;
    margin: auto;
    padding: 1rem;
    grid-template-columns: repeat(4,1fr);
    gap: 1rem;
}

.dashCard{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 20vh;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    background: #FFE505;
    border-radius: 15px;
    font-weight: bold;
    padding: 2rem;
}

.dashCard:hover{
    background: #000;
    color:#FFE505;
    cursor: pointer;
}

.DescripionSpan{
    font-size: 1.2em;
}

.ValueSpan{
    width: auto;
    padding: 0.5rem;
    font-size: 3em;
}

@media screen and (max-width:980px) {

    .DashBoradContainer{
        display: grid;
        height: auto;
        grid-template-columns:1fr;
       
    }
}