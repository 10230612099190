.tableContainer{
    height: 100vh;
    overflow: auto;
}

.tableContainer table{
    width: 100%;
    text-align: center;

    th,td{
        padding: 0.5rem;
        border-bottom: 1px solid rgb(7, 7, 8);
    }

    thead{
        width: 100vw;
        background: #FFE505;
        color:#000;
    }

    tbody tr:hover{
        background: #000;
        color:#FFE505;
        cursor: pointer;
    }
}

