.orderTacker
    {
        display: flex;
        justify-content: space-around;
        margin-top: 5rem;
        width: 98%;

    }

.productContainer{
        overflow: auto;
        height: 50vh;
    }

    .productContainer table{
        width: 100%;
        text-align: center;

        th,td{
            padding: 0.5rem;
            border-bottom: 1px solid rgb(7, 7, 8);
        }

        thead{
            width: 100vw;
            background: #FFE505;
            color:#000;
        }

        tbody tr:hover{
            background: #000;
            color:#FFE505;
            cursor: pointer;
        }
    }



@media screen and (max-width:980px){
    .orderTacker
    {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .productContainer table{
        width: 150%;
    }
}

